import React, { useState, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import "../css/auth.css";
import "../css/logout.css";
import GoogleLogo from "../components/icons/googleLogo.png";
import useAPI from "api/serviceApi";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "redux/userSlice";
import emptyCap from "../components/icons/Group-empty-cap.svg";
import smallPen from "../components/icons/Group-small-pen.svg";
import rightShadow from "../components/icons/Group-shadow-right.svg";
import printMachine from "../components/icons/Group-print-machine.svg";
import leftShadow from "../components/icons/Group-left-shadow.svg";
import pen from "../components/icons/Group-pen.svg";
import cap from "../components/icons/Group-cap.svg";
import rightHand from "../components/icons/Group-right-hand.svg";
import leftHand from "../components/icons/Group-left-hand.svg";
import axios from "axios";
import { setIsLoading } from "redux/loaderSlice";
import Loader from "components/loader/loader";

export function AuthenticationScreen() {
  const { loading } = useSelector((state) => state.loader);
  const [googleData, setGoogleData] = useState(null);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [isOpen, setCloseModal] = useState(false);
  const [googlePopupOpen, setGooglePopupOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isCloseModel, setSetCloseModel] = useState(true);


  const dispatch = useDispatch();

  const localToken = localStorage.getItem("token");

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setGoogleData(tokenResponse);
      dispatch(setIsLoading(false));
    },
    onNonOAuthError: () => {
      dispatch(setIsLoading(false));
    },
  });

  const [isLogoutMessage, setLogoutMessage] = useState(false);
  useEffect(() => {
    const logoutMessage = localStorage.getItem("logoutMessage");
    if (logoutMessage) {
      setLogoutMessage(true)
      localStorage.removeItem("logoutMessage");
    }
  }, []);

  useEffect(() => {
    if (googleData) {
      setToken(googleData.access_token);
    }
  }, [googleData]);

  async function getUserInfo() {
    try {
      const response = await axios.get(
        "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const userData = response.data;
      setUser(userData);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (token) {
      getUserInfo();
      localStorage.setItem("token", token);
    }
  }, [token]);

  useEffect(() => {
    const uploadData = async () => {
      if (user && localToken) {
        const data = await useAPI.registerUser(user, token);
        const registeredUser = data.data.user;
        dispatch(getUser(registeredUser));
      }
    };
    uploadData();
  }, [user, localToken]);

  useEffect(() => {
    setCloseModal(true);
  }, []);

  const handleClick = async () => {
    setGooglePopupOpen(true); // Set the popup state to open before opening the Google sign-in popup
    dispatch(setIsLoading(true));
    try {
      login();
    } catch (e) {
      setGooglePopupOpen(false); // Set the popup state to closed if an error occurs
      dispatch(setIsLoading(false));
      console.log(e);
    }
  };

  const handleModelHide = () => {
    setSetCloseModel(false);
  };

  return (
    <>
      {loading && googlePopupOpen ? (
        <Loader />
      ) : (
        <div className="flex flex-col">
          <div>
            {isLogoutMessage && <div className="logout-message">
            <div>
              <center>
                <div className={isCloseModel ? "confirmation-modal" : "all_set"}>
                  <p>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="22"
                        viewBox="0 0 27 22"
                        fill="none"
                        style={{ position: "absolute", textAlign: "right" }}
                      >
                        <ellipse
                          cx="13.7805"
                          cy="11"
                          rx="12.9925"
                          ry="11"
                          fill="#23D9A5"
                        />
                        <path
                          d="M7.72308 10.9689L11.8917 14.4501L19.2312 8.36725"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span>
                      You’ve been signed out from your Writaa account.
                    </span>{" "}
                    <span
                      className="float-right"
                      style={{ marginTop: "-5px", marginRight: "-20px" }}
                      onClick={handleModelHide}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 38 32"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10.1464 10.1464C9.95118 10.3417 9.95118 10.6583 10.1464 10.8536L15.0962 15.8033L10.1465 20.7531C9.9512 20.9483 9.9512 21.2649 10.1465 21.4602C10.3417 21.6554 10.6583 21.6554 10.8536 21.4602L15.8033 16.5104L20.753 21.4602C20.9483 21.6554 21.2649 21.6554 21.4602 21.4602C21.6554 21.2649 21.6554 20.9483 21.4602 20.753L16.5104 15.8033L21.4602 10.8536C21.6554 10.6583 21.6554 10.3417 21.4602 10.1465C21.2649 9.9512 20.9483 9.9512 20.7531 10.1465L15.8033 15.0962L10.8536 10.1464C10.6583 9.95118 10.3417 9.95118 10.1464 10.1464Z"
                          fill="#1A062E"
                        />
                      </svg>
                    </span>
                  </p>
                </div>
              </center>
            </div>
      </div>}
          </div>

          <div className="bg-[#5C2D8B] max-w-[370px] h-[170px] rounded-xl px-6 py-4 shadow-3xl">
            <div className="content">
              <p className="text-[#E0E0E0] text-center ">
                Use your free account to build a writing habit and write
                everyday.
              </p>
            </div>
            <div onClick={handleClick} className="signInBtn mt-8 bg-white ">
              <img src={GoogleLogo} alt="google logo" />
              <p>Sign in with Google</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
