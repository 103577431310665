import React, { useState } from "react";
import "../css/question_list.css";
import { useDispatch, useSelector } from "react-redux";
import { postServey } from "redux/userSlice";
import { useEffect } from "react";
import logo from "../components/icons/green-check.svg";
export function QuestionList({ setServeyInfo, setModalVisible }) {
  const [disable, setDisable] = useState(false);
  const [options, setOptions] = useState([]);
  const [surveyDone, setSurveyDone] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const userId = useSelector((state) => state.userInfo.user.id);
  // const handleCheckboxChange = (e) => {
  //   const checkboxes = document.querySelectorAll('input[type="checkbox"]');
  //   const checkboxesChecked = Array.from(checkboxes).filter(
  //     (checkbox) => checkbox.checked
  //   );
  //   setOptions((prevState) => {
  //     return [...prevState, e.target.id];
  //   });
  //   // if (checkboxesChecked.length >= 1) {
  //   //   setDisable(true);
  //   // }
  // };
  const handleCheckboxChange = (e) => {
    const optionId = e.target.id;
    // Create a copy of the current options array
    const updatedOptions = [...options];
    if (updatedOptions.includes(optionId)) {
      // Option is already selected, remove it from the list
      const indexToRemove = updatedOptions.indexOf(optionId);
      updatedOptions.splice(indexToRemove, 1);
    } else {
      // Option is not selected, add it to the list
      updatedOptions.push(optionId);
    }
    // Update the options array
    setOptions(updatedOptions);
  };
  console.log(options);
  const handleSubmit = () => {
    if (options.length === 0) {
      setError(true);
    }else {
    //   // setServeyInfo(true);
    //   // dispatch(postServey({ options, userId, token }));
    //   // setModalVisible(false);
      setSurveyDone(true);
      setError(false);
    }
  };
  const gotowrite = () => {
    setServeyInfo(true);
    dispatch(postServey({ options, userId, token }));
  };
  return (
    <div>
      <div className=" shadow-lg ">
        {surveyDone ? (
          <div className="flex flex-col py-4">
            <div className=" w-full flex justify-center my-4">
              <img className=" self-center  " src={logo} alt="logo" />
            </div>
            <div className="text-center text-[20px] font-semibold">
              Account setup complete
            </div>
            <div className="text-center px-8 mt-2 text-[20px]">
              Let’s get stared with your first writing sprint.{" "}
            </div>{" "}
            <br />
            <div className="text-center px-8 mb-4 text-[20px]">
              A writing sprint is a fixed time activity we use to build habits
              and make consistent progress.{" "}
            </div>
            <button
                onClick={gotowrite}
                className="bg-[#5C2D8B] text-white px-6 py-2 self-center rounded-xl"
              >
                 Let’s start a sprint!
              </button>
          </div>
        ) : (
          <>
            <p className="mainTitle my-8 mx-3 text-[#1A062E]">
              What kind of writing projects will you use Writaa for?
            </p>
            <p className=" px-6 mx-3 text-[#1A062E]">
              This helps us improve Writaa to better serve your writing needs
            </p>
            <div className="listBox  mx-6 my-4">
              <div className="checkboxField">
                {/* <input
                  id="novel"
                  type="checkbox"
                  className="customCheckbox"
                  disabled={disable}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="novel" className="labelText">
                  Writting novel
                </label> */}
                <label htmlFor="novel" className="custom-checkbox">
                  <input
                  type="checkbox"
                  id="novel"
                  className="customCheckbox"
                  disabled={disable}
                  onChange={handleCheckboxChange}
                  />
                  <span className="checkmark labelText"></span>
                  Writting novel
                </label>
              </div>
              <div className="checkboxField">
                {/* <input
                  id="non-fiction"
                  type="checkbox"
                  className="customCheckbox "
                  disabled={disable}
                  onChange={handleCheckboxChange}
                />
                <label
                  htmlFor="non-fiction"
                  className="labelText text-[#1A062E]"
                >
                  Writing non-fiction
                </label> */}
                <label htmlFor="non-fiction" className="custom-checkbox">
                  <input
                  type="checkbox"
                  id="non-fiction"
                  className="customCheckbox"
                  disabled={disable}
                  onChange={handleCheckboxChange}
                  />
                  <span className="checkmark labelText"></span>
                  Writing non-fiction
                </label>
              </div>
              <div className="checkboxField">
                {/* <input
                  id="blog"
                  type="checkbox"
                  className="customCheckbox"
                  disabled={disable}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="blog" className="labelText">
                  Writing a blog
                </label> */}
                <label htmlFor="blog" className="custom-checkbox">
                  <input
                  type="checkbox"
                  id="blog"
                  className="customCheckbox"
                  disabled={disable}
                  onChange={handleCheckboxChange}
                  />
                  <span className="checkmark labelText"></span>
                    Writing a blog
                </label>
              </div>
              <div className="checkboxField">
                {/* <input
                  id="other"
                  type="checkbox"
                  className="customCheckbox"
                  disabled={disable}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="other" className="labelText">
                  Other
                </label> */}
                <label htmlFor="other" className="custom-checkbox">
                  <input
                  type="checkbox"
                  id="other"
                  className="customCheckbox"
                  disabled={disable}
                  onChange={handleCheckboxChange}
                  />
                  <span className="checkmark labelText"></span>
                  Other
                </label>
              </div>
            </div>
            {error && (
              <p className="text-center text-red-500 p-1">
                Select at least one answer to continue
              </p>
            )}
            <div className="flex justify-center my-4">
              <button
                onClick={handleSubmit}
                className="bg-[#5C2D8B] text-white px-8 text-lg font-semibold rounded-xl py-2"
              >
                Complete Setup
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}