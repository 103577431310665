import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import extremelyHappyIcon from "../../components/icons/animation_loved.gif";
import happyIcon from "../../components/icons/animation_happy.gif";
import sadIcon from "../../components/icons/animation_normal.gif";
import neutralIcon from "../../components/icons/animation_not_good.gif";
import extremelySadIcon from "../../components/icons/animation_too_sad.gif";
import {
  addMetaData,
  calcDurationAndSpeed,
  postSprint,
  deleteSprintInfo,
} from "redux/noteEditorSlice";
import { verifyToken } from "api/verifyToken";
import { deleteUserInfo } from "redux/userSlice";
const smiles = [
  { id: 0, title: "excited", img: extremelyHappyIcon },
  { id: 1, title: "good", img: happyIcon },
  { id: 2, title: "normal", img: sadIcon },
  { id: 3, title: "sad", img: neutralIcon },
  { id: 4, title: "extremely sad", img: extremelySadIcon },
];
export const FormHOC = (Component) => {
  const WithHOC = (props) => {
    const [form, setForm] = useState({
      title: "",
      project: "",
      emotion: "",
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const speed = useSelector((state) => state.noteEditor.sprint.speed);
    const userId = useSelector((state) => state.userInfo.user.id);
    const sprint = useSelector((state) => state.noteEditor.sprint);
    const token = localStorage.getItem("token");
    useEffect(() => {
      async function validateToken() {
        const session = await verifyToken(token);
        if (!session) {
          dispatch(deleteUserInfo());
          navigate("/");
        }
      }
      validateToken();
    }, []);
    useEffect(() => {
      dispatch(addMetaData({ form, sprintId: props.sprintId }));
    }, [form]);
    const handleSubmit = (e) => {
      e.preventDefault();
      if (props.type === "Save") {
        dispatch(
          calcDurationAndSpeed({
            duration: props.durationSeconds,
            id: props.sprintId,
          })
        );
        if (speed) {
          dispatch(postSprint({ sprint, userId, token }));
          navigate("/dashboard");
          const saveTimeMessage = localStorage.getItem("timeDuration2");
          const savewordCountMessage = localStorage.getItem("wordCount");
          localStorage.setItem('savewordCountMessage', savewordCountMessage);
          localStorage.setItem('saveTimeMessage', saveTimeMessage);
          localStorage.removeItem("autosavedText");
          localStorage.removeItem("timeDuration");
          localStorage.removeItem("timeDuration2");
          localStorage.removeItem("setOldValue");
          localStorage.removeItem("wordCount");
          localStorage.setItem("saveSprintMessage", "Save Sprint");
          dispatch(deleteSprintInfo());
        }
      } else {
        props.closeModal(false);
        props.setIsStopped(false);
        props.setStatus(props.STATUS.STARTED);
      }
    };
    const handleStateChange = (fieldName, value) => {
      setForm((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    };
    const handleCloseModal = () => {
      props.closeModal(false);
      props.setIsStopped(false);
      props.setStatus(props.STATUS.STARTED);
      if (props.type === "Save") {
        navigate(0);
      }
    };
    const handleCloseModalSprint = () => {
      props.closeModal(false);
      props.setIsStopped(false);
      props.setStatus(props.STATUS.STARTED);
      if (props.type === "Save") {
        navigate(0);
      }
    };
    const handleDelete = () => {
      if (props.type === "Edit") {
        setForm(() => {
          return {
            title: "",
            project: "",
            emotion: "",
          };
        });
        props.closeModal(false);
        props.setIsStopped(false);
        props.setStatus(props.STATUS.STARTED);
      } else if (props.type === "Save") {
        window.location.reload();
        localStorage.removeItem("autosavedText");
        localStorage.removeItem("timeDuration");
        localStorage.removeItem("timeDuration2");
        localStorage.removeItem("wordCount");
        // navigate("/");
        dispatch(deleteSprintInfo());
      }
    };
    const handleSubmitNewSprint = (e) => {
      e.preventDefault();
      if (props.type === "Save") {
        dispatch(
          calcDurationAndSpeed({
            duration: props.durationSeconds,
            id: props.sprintId,
          })
        );
        if (speed) {
          dispatch(postSprint({ sprint, userId, token }));
          // navigate("/");
          localStorage.removeItem("autosavedText");
          localStorage.removeItem("timeDuration");
          localStorage.removeItem("wordCount");
          localStorage.removeItem("timeDuration2");
          // dispatch(deleteSprintInfo());
          window.location.reload();
        }
      } else {
        props.closeModal(false);
        props.setIsStopped(false);
        props.setStatus(props.STATUS.STARTED);
      }
    };
    return (
      <Component
        openModal={props.openModal}
        type={props.type}
        handleSubmit={handleSubmit}
        handleStateChange={handleStateChange}
        handleCloseModal={handleCloseModal}
        handleCloseModalSprint={handleCloseModalSprint}
        handleDelete={handleDelete}
        STATUS={props.STATUS}
        smiles={smiles}
        form={form}
        setForm={setForm}
        handleSubmitNewSprint={handleSubmitNewSprint}
        countword = {sprint.countWords}
        setOpenModal={props.setOpenModal}
        setIsPaused= {props.setIsPaused}
        isPaused={props.isPaused}
        count= {props.count}
        setPausedSeconds={props.setPausedSeconds}
        seconds={props.seconds}
        secondsRemaining={props.secondsRemaining}
        setSecondsRemaining={props.setSecondsRemaining}
        pausedSeconds={props.pausedSeconds}
        setType ={props.setType}
        sprintData= {props.sprintData}
        setsprintData ={props.setsprintData}
      />
    );
  };
  return WithHOC;
};