import axios from "axios";

const url = "";

const useAPI = {
  registerUser: async (user, token) => {
    try {
      const userData = { ...user, token: token };
      const response = await axios.post(`${url}/api/auth`, userData, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });
      const email = response.data.user.email;
      const userId = response.data.user.id.toString();
      const firstName = response.data.user.given_name;
      const lastName = response.data.user.family_name;
      const getData = await axios.get(`https://api.brevo.com/v3/contacts`, {
        headers: {
          accept: "application/json",
          "api-key": `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`,
        },
      });
      const dataEmail = getData.data.contacts;
      const filteredData = dataEmail.filter(
        (contact) => contact.email === email
      );
      if (filteredData.length === 0) {
        const userData1 = {
          email: email,
          ext_id: userId,
          attributes: {
            FNAME: firstName,
            LNAME: lastName,
          },
          emailBlacklisted: false,
          smsBlacklisted: false,
          listIds: [10, 4 ],
          updateEnabled: false,
          smtpBlacklistSender: [email],
        };
        const addBrovoList = await axios.post(
          `https://api.brevo.com/v3/contacts`,
          userData1,
          {
            headers: {
              Accept: "application/json",
              "api-key": `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`,
            },
          }
        );
      }
      console.log("REGISTER", response);
      return response;
    } catch (e) {
      console.log(e);
    } finally {
      console.log("register user");
    }
  },
  uploadSprint: async (
    sprint = { content: "empty sprint`s content" },
    userId = 123,
    token
  ) => {
    try {
      const postingData = { ...sprint, userId };

      const response = await axios.post(`${url}/api/post-sprint`, postingData, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      return response;
    } catch (e) {
      console.log(e);
    } finally {
      console.log("update sprint");
    }
  },
  fetchSprints: async (userId, token) => {
    try {
      const response = await axios.get(`${url}/api/${userId}/sprints`, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });
      return response;
    } catch (e) {
      console.log(e);
    } finally {
      console.log("fetch sprints");
    }
  },
  sendUpdatedSprint: async (updatedSprint, token) => {
    try {
      const response = await axios.put(
        `${url}/api/update-sprint`,
        updatedSprint,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      return response;
    } catch (e) {
      console.log(e);
    } finally {
      console.log("update sprint");
    }
  },
  postServey: async (options, userId, token) => {
    try {
      const response = await axios.put(
        `${url}/api/post-servey`,
        { options: options, userId },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
      return response;
    } catch (e) {
      console.log(e);
    } finally {
      console.log("post servey");
    }
  },

  sendDeletedSprint: async (sprintId, token) => {
    try {
      const response = await axios.post( // Change axios.delete to axios.post
        `${url}/api/delete-sprint`, // Update the endpoint to use POST
        { sprintId }, // Pass sprintId in the request body
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (e) {
      console.error("Error deleting sprint:", e);
    } finally {
      console.log("delete sprint");
    }
  },
};


export default useAPI;
