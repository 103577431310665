// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.new_logo{
  position: absolute !important;
  top: 50px !important;
  width: 160px;
}
.min-h-screen {
    min-height: 78vh !important;
  }
  .border-black {
    /* --tw-border-opacity: 1; */
    border-color: none;
    transform: scale(1.2);
    border: none;
}
textarea#customTextarea {
  background: white;
}
.singal-rounded{
  border-radius: 0px 15px 15px 0px;
}
.pb-\\[28px\\].overflow-y-scroll.scrollbar-hide {
  overflow-x: hidden;
}
@media screen and (min-width:1000px) and (max-width: 1800px){
  p.text-\\[14px\\].font-\\[400\\].font-mulish.text-\\[\\#5C2D8B\\].pb-\\[18px\\]{
    width: 70%;
  }
}
@media screen and (min-width:776px) and (max-width: 1000px){
  p.text-\\[14px\\].font-\\[400\\].font-mulish.text-\\[\\#5C2D8B\\].pb-\\[18px\\]{
    width: 50%;
  }
}
@media screen and (min-width:320px) and (max-width: 776px){
  p.text-\\[14px\\].font-\\[400\\].font-mulish.text-\\[\\#5C2D8B\\].pb-\\[18px\\]{
    width: 30%;
  }
}


.d-flex.mt-12.mb-7 {
  padding-left: 90px;
}
h5.mt-3.text-\\[\\#5C2D8B\\] {
  text-align: center;
}

 .add_new_font_size{
  width: 80%;
}`, "",{"version":3,"sources":["webpack://./src/css/newoutput.css"],"names":[],"mappings":";AACA;EACE,6BAA6B;EAC7B,oBAAoB;EACpB,YAAY;AACd;AACA;IACI,2BAA2B;EAC7B;EACA;IACE,4BAA4B;IAC5B,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;AAChB;AACA;EAIE,iBAAiB;AAFnB;AAIA;EACE,gCAAgC;AAClC;AACA;EACE,kBAAkB;AACpB;AACA;EACE;IACE,UAAU;EACZ;AACF;AACA;EACE;IACE,UAAU;EACZ;AACF;AACA;EACE;IACE,UAAU;EACZ;AACF;;;AAGA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;;CAEC;EACC,UAAU;AACZ","sourcesContent":["\n.new_logo{\n  position: absolute !important;\n  top: 50px !important;\n  width: 160px;\n}\n.min-h-screen {\n    min-height: 78vh !important;\n  }\n  .border-black {\n    /* --tw-border-opacity: 1; */\n    border-color: none;\n    transform: scale(1.2);\n    border: none;\n}\ntextarea#customTextarea {\n  background: white;\n}\ntextarea#customTextarea {\n  background: white;\n}\n.singal-rounded{\n  border-radius: 0px 15px 15px 0px;\n}\n.pb-\\[28px\\].overflow-y-scroll.scrollbar-hide {\n  overflow-x: hidden;\n}\n@media screen and (min-width:1000px) and (max-width: 1800px){\n  p.text-\\[14px\\].font-\\[400\\].font-mulish.text-\\[\\#5C2D8B\\].pb-\\[18px\\]{\n    width: 70%;\n  }\n}\n@media screen and (min-width:776px) and (max-width: 1000px){\n  p.text-\\[14px\\].font-\\[400\\].font-mulish.text-\\[\\#5C2D8B\\].pb-\\[18px\\]{\n    width: 50%;\n  }\n}\n@media screen and (min-width:320px) and (max-width: 776px){\n  p.text-\\[14px\\].font-\\[400\\].font-mulish.text-\\[\\#5C2D8B\\].pb-\\[18px\\]{\n    width: 30%;\n  }\n}\n\n\n.d-flex.mt-12.mb-7 {\n  padding-left: 90px;\n}\nh5.mt-3.text-\\[\\#5C2D8B\\] {\n  text-align: center;\n}\n\n .add_new_font_size{\n  width: 80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
