import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../css/dashboard.css";
import { getSprints } from "redux/noteEditorSlice";
import { verifyToken } from "api/verifyToken";
import { deleteUserInfo } from "redux/userSlice";
import { useNavigate } from "react-router-dom";
import logo from "../../components/icons/logo.png";
import { SprintInfoSmall } from "views/Dashboard/Sprints/SprintInfoSmall";
import { SprintInfo } from "views/Dashboard/Sprints/SprintsInfo";
import newlogo from "../../components/icons/nanowrimo 1.png";

export function NanoTracker() {
  const sprints = useSelector((state) => state.noteEditor.sprints);
  const userId = useSelector((state) => state.userInfo.user.id);
  const userPhoto = useSelector((state) => state.userInfo.user.picture);
  const token = localStorage.getItem("token");
  const [type, setType] = useState("view");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMoblieWidth, setMobileWidth] = useState(false);
  const [isActive, setActive] = useState(null);
  const [isSprintInfo, setSprintInfo] = useState(null);
  const [isProfile, setIsProfile] = useState(false);
  let uniqueDates = new Set();
  let combinedData = {};
  let totalWords = {};
  let totalSprints = {};
  let formattedTime = {};
  let totalSeconds2 = 0;
  let allwordsCount = 0;
  let allsprintsCount = 0;

  useEffect(() => {
    async function validateToken() {
      const session = await verifyToken(token);
      if (!session) {
        dispatch(deleteUserInfo());
        navigate("/");
      }
    }
    validateToken();
  }, []);
  useEffect(() => {
    dispatch(getSprints({ userId, token }));
    if (window.innerWidth <= 959) {
      setMobileWidth(true);
    } else {
      setMobileWidth(false);
    }
  }, [sprints.length]);

  const handleSignOutClick = () => {
    localStorage.removeItem("token");
    setIsProfile(false);
    localStorage.setItem(
      "logoutMessage",
      "You have been successfully logged out!"
    );
    window.location.reload();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    let dayStr;
    if (day > 3 && day < 21) {
      dayStr = `${day}th`;
    } else {
      switch (day % 10) {
        case 1:
          dayStr = `${day}st`;
          break;
        case 2:
          dayStr = `${day}nd`;
          break;
        case 3:
          dayStr = `${day}rd`;
          break;
        default:
          dayStr = `${day}th`;
      }
    }
    const options = { month: "short" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return `${dayStr} ${formattedDate}`;
  };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const startDate = currentYear + "-11-01"; // Start date in "YYYY-MM-DD" format
  const endDate = currentYear + "-11-31";

  sprints.forEach((item) => {
    const date = item.created_at.split("T")[0];
    if (date >= startDate && date <= endDate) {
      if (combinedData[date]) {
        combinedData[date].push(item);
      } else {
        combinedData[date] = [item];
        uniqueDates.add(date);
      }
    }
  });

  Object.keys(combinedData).forEach((date) => {
    totalWords[date] = combinedData[date].reduce(
      (acc, item) => acc + item.count_words,
      0
    );
    allwordsCount += combinedData[date].reduce(
      (acc, item) => acc + item.count_words,
      0
    );

    totalSprints[date] = combinedData[date].length;

    allsprintsCount += combinedData[date].length;
    let totalSeconds = combinedData[date].reduce((acc, item) => {
      if (item.duration.includes("min")) {
        const timeParts = item.duration.split(" ");
        const min = parseInt(timeParts[0]);
        const sec = timeParts.length > 2 ? parseInt(timeParts[2]) : 0;
        return acc + min * 60 + sec;
      } else if (item.duration.includes("sec")) {
        return acc + parseInt(item.duration);
      } else {
        return acc + parseFloat(item.duration) * 3600;
      }
    }, 0);
    totalSeconds2 += totalSeconds;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    formattedTime[date] =
      hours > 0
        ? `${hours} hours ${minutes} min ${seconds} sec`
        : `${minutes} min ${seconds} sec`; // Use date as the key
  });

  const totalDays = uniqueDates.size;
  const totalHoursOverall = Math.floor(totalSeconds2 / 3600);
  const totalMinutesOverall = Math.floor((totalSeconds2 % 3600) / 60);
  const totalSecondsOverall = totalSeconds2 % 60;
  const totalFormattedTime =
    totalHoursOverall > 0
      ? `${totalHoursOverall} hours ${totalMinutesOverall} min ${totalSecondsOverall} sec`
      : `${totalMinutesOverall} min ${totalSecondsOverall} sec`;

  // ========== CURRENT DATE =============================

  function formatDateCurrent(date) {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day}${getDaySuffix(day)} ${month} ${year}`;
  }

  function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }
  const formattedDate = formatDateCurrent(currentDate);

  return (
    <div
      className={
        isMoblieWidth
          ? "min-h-screen bg-[#F4F1F9]"
          : "bg-[#f4f4f4be] min-h-screen pb-10 lg:flex flex-col items-center"
      }
    >
      <div
        className={
          isMoblieWidth
            ? "w-full h-[80px] p-[20px] flex justify-between items-center"
            : "w-full h-[80px] p-10 flex justify-between items-center"
        }
      >
        {/* {isMoblieWidth && ( */}
        <img src={logo} alt="logo" style={{ width: "90px", height: "24px" }} />
        {/* )} */}
        <div
          className={
            isMoblieWidth
              ? "flex items-center gap-[20px]"
              : "flex justify-end gap-10"
          }
        >
          <button
            onClick={() => navigate("/dashboard")}
            className="h-[40px] px-[28px] rounded-[10px] bg-[#5C2D8B] font-mulish text-[18px] font-[700] text-white"
            style={{ background: "white", color: "black", display: "flex" }}
          >
            <span style={{ padding: "5px", display: "flex" }}>
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="14"
                viewBox="0 0 8 14"
                style={{ marginTop: "7px", marginRight: "7px" }}
                fill="none"
              >
                <path
                  d="M7 1L1 7L7 13"
                  stroke="#1A062E"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Back to My Sprints
            </span>
          </button>
          <button
            onClick={() => navigate("/note-editor")}
            className="h-[40px] px-[28px] rounded-[10px] bg-[#5C2D8B] font-mulish text-[18px] font-[700] text-white"
          >
            Write
          </button>
          <img
            src={userPhoto}
            style={{
              width: "39px",
              height: "39px",
              borderRadius: "10px",
              border: "2px solid #1A062E",
              cursor: "pointer",
            }}
            alt="user`s photo"
            onClick={() => setIsProfile(!isProfile)}
          />
          {isProfile && (
            <div className="new_logout" onClick={handleSignOutClick}>
              Sign Out
            </div>
          )}
        </div>
      </div>
      <div
        className={
          isMoblieWidth
            ? ""
            : "flex bg-[#E6D8F3] mx-10 rounded-[15px] h-[86vh] md:mx-20 lg:w-11/12 "
        }
      >
        <div
          className="pb-[28px] overflow-y-scroll scrollbar-hide"
          style={{ background: "white" }}
        >
          <div
            className={
              isMoblieWidth
                ? "flex p-[20px] gap-2"
                : "w-[260px]  flex md:w-[320px]"
            }
            style={{ padding: "15px 1px 0px 40px" }}
          >
            <img
              src={newlogo}
              alt="newlogo"
              style={{ width: "213px", height: "" }}
            />
          </div>
          <div className="p-2">
            <p className="text-center text-[#A6A3A9] text-[14px] font-[400] font-mulish">
              {formattedDate}
            </p>
            <hr className="mt-2 " />

            <p className="text-center text-[#A6A3A9] text-[14px] font-[400] font-mulish mt-5">
              DAY
            </p>
            <p
              className="text-center text-[17px] font-[400] font-mulish mt-1"
              style={{ color: "black" }}
            >
              <b>{totalDays}</b>
            </p>

            <p className="text-center text-[#A6A3A9] text-[14px] font-[400] font-mulish mt-5">
              WORDS
            </p>
            <p
              className="text-center text-[17px] font-[400] font-mulish mt-1"
              style={{ color: "black" }}
            >
              <b>{allwordsCount}</b>
            </p>

            <p className="text-center text-[#A6A3A9] text-[14px] font-[400] font-mulish mt-5">
              SPRINTS
            </p>
            <p
              className="text-center text-[17px] font-[400] font-mulish mt-1"
              style={{ color: "black" }}
            >
              <b>{allsprintsCount}</b>
            </p>

            <p className="text-center text-[#A6A3A9] text-[14px] font-[400] font-mulish mt-5">
              WRITING TIME
            </p>
            <p
              className="text-center text-[17px] font-[400] font-mulish mt-1"
              style={{ color: "black" }}
            >
              <b>{totalFormattedTime}</b>
            </p>
          </div>
          <aside
            className={
              isMoblieWidth
                ? "last:border-b-0"
                : "w-[260px] md:w-[320px] pt-[28px] pl-[28px] m-0 space-y-[16px] relative "
            }
          ></aside>
        </div>
        {isMoblieWidth ? (
          <div>
            {sprints.map((sprint) => {
              return (
                isSprintInfo && (
                  <SprintInfoSmall
                    key={sprint.id}
                    sprint={sprint}
                    isSprintInfo={isSprintInfo}
                    setSprintInfo={setSprintInfo}
                    type={type}
                    setType={setType}
                  />
                )
              );
            })}
          </div>
        ) : (
          <article className=" bg-white singal-rounded w-[100%] p-10 lg:w-full overflow-y-scroll scrollbar-hide">
            {sprints.map((sprintInfo, index) => {
              return (
                <SprintInfo
                  key={sprintInfo.id}
                  isActive={isActive}
                  sprint={sprintInfo}
                  type={type}
                  setType={setType}
                  index={index + 1}
                />
              );
            })}

            <div className="w-full ">
              <div className="flex justify-between  mb-[20px] border-b-2">
                <div
                  className=" border-[#5C2D8B]"
                  style={{
                    borderBottomWidth: "6px",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  <p className="font-mulish text-[#5C2D8B] text-[18px] font-[700] mb-4">
                    DAILY PROGRESS
                  </p>
                </div>
                <div className="btnContainer"></div>
              </div>
              <div className=" ">
                <div>
                  {Object.keys(combinedData).map((date) => (
                    <div key={date}>
                      <p className="text-[#A6A3A9] text-[15px] font-[400] font-mulish mb-4">
                        {formatDate(date)}
                      </p>
                      <div className="pb-10 border-b-2">
                        <p>
                          <p className="text-[17px] font-[600] font-mulish mt-1">
                            {totalWords[date]} words
                          </p>
                          <p className="text-[#A6A3A9] text-[17px] font-[400] font-mulish mt-2">
                            {totalSprints[date]} Sprints, {formattedTime[date]}
                          </p>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </article>
        )}
      </div>
    </div>
  );
}
