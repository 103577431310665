import { useState, useEffect } from "react";
export function SprintsDescktopScreen({ sprint, isActive, setActive, firstId, index }) {
  useEffect(() =>{
    setActive(firstId.id);
  },[])

  const subject = (sprint.title && sprint.title.length > 30) ? sprint.title.substring(0, 30) + "......" : (sprint.title || "Sprint " + index);


  return (
    <>
      <div
        className={`pt-[20px] cursor-pointer pl-[28px] pb-[18px]
        ${
          (isActive == sprint.id) &&
          "bg-white transition duration-300 rounded-l-3xl ease-in-out"
        }
        `}
        onClick={() => {
          setActive(sprint.id);
        }}
      >
        <p className={`text-[16px] font-[700] font-mulish ${isActive === sprint.id ? 'text-[#5C2D8B]' : 'text-[#00000]' }`} >
          {sprint.count_words} words
        </p>
        <p className={`text-[14px] font-[400] add_new_font_size font-mulish ${isActive === sprint.id ? 'text-[#5C2D8B]' : 'text-[#00000]'} pb-[18px]`}>
        {subject}
        </p>
        <p className={`text-[14px] font-[400] font-mulish ${isActive === sprint.id ? 'text-[#5C2D8B]' : 'text-[#00000]' }`}>
          {sprint.duration}
        </p>
      </div>
    </>
  );
}









