import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SprintsSmallScreen } from "./Sprints/SprintsSmallScreen";
import book from "../../components/icons/outline.svg";
import { SprintsDescktopScreen } from "./Sprints/SprintsDescktopScreen";
import { SprintInfo } from "./Sprints/SprintsInfo";
import { SprintInfoSmall } from "./Sprints/SprintInfoSmall";
import "../../css/dashboard.css";
import { getSprints } from "redux/noteEditorSlice";
import { verifyToken } from "api/verifyToken";
import { deleteUserInfo } from "redux/userSlice";
import { Navigate, useNavigate } from "react-router-dom";
import logo from "../../components/icons/logo.png";
import nanowrino from "../../components/icons/Logo-Lockup.png";
const STATUS = {
  STARTED: "Started",
  STOPPED: "Stopped",
};
export function Dashboard() {
  const sprints = useSelector((state) => state.noteEditor.sprints);
  const userId = useSelector((state) => state.userInfo.user.id);
  const userPhoto = useSelector((state) => state.userInfo.user.picture);
  const token = localStorage.getItem("token");
  const [type, setType] = useState("view");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMoblieWidth, setMobileWidth] = useState(false);
  const [isActive, setActive] = useState(null);
  const [isSprintInfo, setSprintInfo] = useState(null);
  const [isProfile, setIsProfile] = useState(false);
  const [isCloseModel, setSetCloseModel] = useState(true);
  useEffect(() => {
    async function validateToken() {
      const session = await verifyToken(token);
      if (!session) {
        dispatch(deleteUserInfo());
        navigate("/");
      }
    }
    validateToken();
  }, []);
  useEffect(() => {
    dispatch(getSprints({ userId, token }));
    if (window.innerWidth <= 959) {
      setMobileWidth(true);
    } else {
      setMobileWidth(false);
    }
  }, [sprints.length]);
  const [isSprintMessage, setSprintMessage] = useState(false);
  const saveTimeMessage = localStorage.getItem("saveTimeMessage");
  const savewordCountMessage = localStorage.getItem("savewordCountMessage");
  useEffect(() => {
    const saveSprintMessage = localStorage.getItem("saveSprintMessage");
    if (saveSprintMessage) {
      setSprintMessage(true);
      localStorage.removeItem("saveSprintMessage");
    }
  }, []);
  const handleSignOutClick = () => {
    localStorage.removeItem("token"); // Clear the token from localStorage
    setIsProfile(false);
    localStorage.setItem(
      "logoutMessage",
      "You have been successfully logged out!"
    );
    window.location.reload();
  };
  const handleModelHide = () => {
    setSetCloseModel(false);
  };
  // =================================================
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear(); // Get the current year dynamically
  const targetStartDate = new Date(currentYear, 9, 25); // October is represented by 9 as it is zero-indexed
  const targetEndDate = new Date(currentYear, 10, 1); // October is represented by 9 as it is zero-indexed
  const targetStartMonth = new Date(currentYear, 10, 1);
  const targetEndMonth = new Date(currentYear, 10, 30);

  const [value, setValue] = useState(0 * 0);
  const [status, setStatus] = useState(STATUS.STOPPED);
  const INITIAL_COUNT = value;
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const [count, setCount] = useState(false);
  const [isCountingUp, setIsCountingUp] = useState(false);
  const [seconds, setSeconds] = useState(1);
  const [countWord, setCountWord] = useState();

  let secondsToDisplay = secondsRemaining;
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (currentDate >= targetStartDate && currentDate <= targetEndDate) {
        const timeDifferenceInMilliseconds =
          targetEndDate.getTime() - currentDate.getTime();
        const timeDifferenceInMinutes = Math.floor(
          timeDifferenceInMilliseconds / 60000
        );
        setValue(timeDifferenceInMinutes * 60);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    setStatus(STATUS.STARTED);
    setSecondsRemaining(INITIAL_COUNT);
    if (value === 600000) {
      setCount(true);
    }
  }, [value]);
  useInterval(
    () => {
      if (isCountingUp) {
        if (secondsRemaining === 1) {
          // Add 15 minutes (900 seconds) and start counting from 16 minutes (960 seconds)
          setSecondsRemaining(INITIAL_COUNT);
        } else {
          // Counting up from 1 to infinity
          if (count) {
            setSecondsRemaining(formatTime(seconds));
          } else {
            setSecondsRemaining((seconds) => seconds + 1);
          }
        }
      } else if (secondsRemaining > 0) {
        setSecondsRemaining((seconds) => seconds - 1);
      } else {
        // Switch to counting up when seconds reach 0
        setIsCountingUp(true);
      }
    },
    status === STATUS.STARTED ? 1000 : null
  );
  function convertToHHMM(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    // Ensure the hours, minutes, and seconds are displayed with leading zeros if needed
    const formattedTime =
      (hours < 10 ? "0" + hours : hours) +
      ":" +
      (minutes < 10 ? "0" + minutes : minutes) +
      ":" +
      (seconds < 10 ? "0" + seconds : seconds);
    return formattedTime;
  }

  function convertToHHMMToScreen(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    // Build the string
    let formattedTime = "";
    if (hours > 0) {
      formattedTime += hours + (hours === 1 ? " hour " : " hours ");
    }
    if (minutes > 0) {
      formattedTime += minutes + (minutes === 1 ? " minute " : " minutes ");
    }
    if (seconds > 0) {
      formattedTime += seconds + (seconds === 1 ? " second" : " seconds");
    }
    return formattedTime.trim();
  }

  useEffect(() => {
    // Assuming you have startDate and endDate set elsewhere
    const startDate = new Date(currentYear, 10, 1);
    const endDate = new Date(currentYear, 10, 30);
    const filteredSprints = sprints.filter((sprint) => {
      const sprintDate = new Date(sprint.created_at);
      return sprintDate >= startDate && sprintDate <= endDate;
    });
    const countWordsArray = filteredSprints.map((sprint) => sprint.count_words);
    const sum = countWordsArray.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    setCountWord(sum);
  }, [sprints]);

  return (
    <div
      className={
        isMoblieWidth
          ? "min-h-screen bg-[#F4F1F9]"
          : "bg-[#f4f4f4be] min-h-screen pb-10 lg:flex flex-col items-center"
      }
    >
      <div
        className={
          isMoblieWidth
            ? "w-full h-[80px] p-[20px] flex justify-between items-center"
            : "w-full h-[80px] p-10 flex justify-between items-center"
        }
      >
        <img src={logo} alt="logo" style={{ width: "90px", height: "24px" }} />
        <div
          className={
            isMoblieWidth
              ? "flex items-center gap-[20px]"
              : "flex justify-end gap-10"
          }
        >
          {currentDate >= targetStartDate && currentDate <= targetEndDate && (
            <>
              <button
                // onClick={() => navigate("/nano-tracker")}
                className="h-[40px] px-[28px] rounded-[10px] bg-[#5C2D8B] font-mulish text-[18px] font-[700] text-white"
                style={{ background: "white", color: "black", display: "flex" }}
              >
                <span>
                  <img
                    src={nanowrino}
                    alt="logo"
                    style={{
                      width: "150px",
                      marginTop: "10px",
                      padding: "4px",
                    }}
                  />
                </span>{" "}
                <span style={{ padding: "8px", fontFamily: "monospace" }}>
                  {convertToHHMM(secondsToDisplay)}
                </span>
              </button>
              <button
                onClick={() => navigate("/note-editor")}
                className="h-[40px] px-[28px] rounded-[10px] bg-[#5C2D8B] font-mulish text-[18px] font-[700] text-white"
              >
                Write
              </button>
            </>
          )}

          {currentDate >= targetStartMonth && currentDate <= targetEndMonth && (
            <>
              <button
                onClick={() => navigate("/nano-tracker")}
                className="h-[40px] px-[28px] rounded-[10px] bg-[#5C2D8B] font-mulish text-[18px] font-[700] text-white"
                style={{ background: "white", color: "black", display: "flex" }}
              >
                <span>
                  <img
                    src={nanowrino}
                    alt="logo"
                    style={{
                      width: "150px",
                      marginTop: "10px",
                      padding: "4px",
                    }}
                  />
                </span>{" "}
                <span
                  style={{
                    padding: "8px",
                    fontFamily: "monospace",
                    display: "flex",
                  }}
                >
                  {countWord + " "} / 50,000
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    style={{ marginTop: "6px", marginLeft: "6px" }}
                  >
                    <path
                      d="M1 1L7 7L1 13"
                      stroke="#1A062E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </button>
              <button
                onClick={() => navigate("/note-editor")}
                className="h-[40px] px-[28px] rounded-[10px] bg-[#5C2D8B] font-mulish text-[18px] font-[700] text-white"
              >
                Write
              </button>
            </>
          )}

          <img
            src={userPhoto}
            style={{
              width: "39px",
              height: "39px",
              borderRadius: "10px",
              border: "2px solid #1A062E",
              cursor: "pointer",
            }}
            alt="user`s photo"
            onClick={() => setIsProfile(!isProfile)}
          />
          {isProfile && (
            <div className="new_logout" onClick={handleSignOutClick}>
              Sign Out
            </div>
          )}
        </div>
      </div>
      <div
        className={
          isMoblieWidth
            ? ""
            : "flex bg-[#E6D8F3] mx-10 rounded-[15px] h-[86vh] md:mx-20 lg:w-11/12 "
        }
      >
        <div className="pb-[28px] overflow-y-scroll scrollbar-hide">
          <div
            className={
              isMoblieWidth
                ? "flex p-[20px] gap-2"
                : "w-[260px] p-[28px] flex md:w-[320px]"
            }
          >
            <img
              src={book}
              alt="book"
              style={{ width: "22px", height: "22px" }}
            />
            {/* <h4 className="font-mulish text-xl font-semibold">Sprints</h4> */}
            <h4
              className="font-mulish text-xl font-semibold"
              style={{
                color: "#1A062E",
                fontWeight: "700",
                marginTop: "-3px",
                marginLeft: "5px",
              }}
            >
              {" "}
              Sprints
            </h4>
          </div>
          <aside
            className={
              isMoblieWidth
                ? "last:border-b-0"
                : "w-[260px] md:w-[320px] pt-[28px] pl-[28px] m-0 space-y-[16px] relative "
            }
          >
            {sprints
              .slice()
              .reverse()
              .map((sprint, index) => {
                const reverseIndex = sprints.length - index;
                const isLastEl = index === sprints.length - 1;
                return isMoblieWidth ? (
                  !isSprintInfo && (
                    <div key={sprint.id} className="flex flex-col">
                      <SprintsSmallScreen
                        sprint={sprint}
                        setSprintInfo={setSprintInfo}
                        isLastEl={isLastEl}
                      />
                    </div>
                  )
                ) : (
                  <div>
                    <SprintsDescktopScreen
                      key={sprint.id}
                      sprint={sprint}
                      isActive={isActive}
                      setActive={setActive}
                      isMoblieWidth={isMoblieWidth}
                      firstId={sprints[sprints.length - 1]}
                      index={reverseIndex}
                    />
                  </div>
                );
              })}
          </aside>
        </div>
        {isMoblieWidth ? (
          <div>
            {sprints.map((sprint) => {
              return (
                isSprintInfo && (
                  <SprintInfoSmall
                    key={sprint.id}
                    sprint={sprint}
                    isSprintInfo={isSprintInfo}
                    setSprintInfo={setSprintInfo}
                    type={type}
                    setType={setType}
                  />
                )
              );
            })}
          </div>
        ) : (
          <article className=" bg-white singal-rounded w-[100%] p-10 lg:w-full overflow-y-scroll scrollbar-hide">
            {sprints.map((sprintInfo, index) => {
              return (
                <SprintInfo
                  key={sprintInfo.id}
                  isActive={isActive}
                  sprint={sprintInfo}
                  type={type}
                  setType={setType}
                  index={index + 1}
                />
              );
            })}
          </article>
        )}
      </div>
      {/* -------------------message --------------- */}
      {isSprintMessage && (
        <div>
          <center>
            <div className={isCloseModel ? "confirmation-modal" : "all_set"}>
              <p>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    style={{
                      position: "absolute",
                      textAlign: "right",
                    }}
                  >
                    <circle cx="11" cy="11" r="11" fill="#23D9A5" />
                    <path
                      d="M5.87185 10.9689L9.40119 14.4501L15.6152 8.36725"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span>
                  Congratulations! You just wrote {savewordCountMessage} words
                  in {convertToHHMMToScreen(saveTimeMessage)}{" "}
                </span>{" "}
                <span
                  className="float-right"
                  style={{
                    marginTop: "-5px",
                    marginRight: "-20px",
                  }}
                  onClick={handleModelHide}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 38 32"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.1464 10.1464C9.95118 10.3417 9.95118 10.6583 10.1464 10.8536L15.0962 15.8033L10.1465 20.7531C9.9512 20.9483 9.9512 21.2649 10.1465 21.4602C10.3417 21.6554 10.6583 21.6554 10.8536 21.4602L15.8033 16.5104L20.753 21.4602C20.9483 21.6554 21.2649 21.6554 21.4602 21.4602C21.6554 21.2649 21.6554 20.9483 21.4602 20.753L16.5104 15.8033L21.4602 10.8536C21.6554 10.6583 21.6554 10.3417 21.4602 10.1465C21.2649 9.9512 20.9483 9.9512 20.7531 10.1465L15.8033 15.0962L10.8536 10.1464C10.6583 9.95118 10.3417 9.95118 10.1464 10.1464Z"
                      fill="#1A062E"
                    />
                  </svg>
                </span>
              </p>
            </div>
          </center>
        </div>
      )}
    </div>
  );
}
function useInterval(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
