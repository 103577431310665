// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.customStartBtn {
    color: white;
    background-color: #6557F5;
    border-radius: 5px;
    padding: 10px 20px;
}
.customStartBtn:hover{
    background-color: #5749f0;
    
}`, "",{"version":3,"sources":["webpack://./src/css/welcome_page.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,yBAAyB;;AAE7B","sourcesContent":[".wrapper{\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.customStartBtn {\n    color: white;\n    background-color: #6557F5;\n    border-radius: 5px;\n    padding: 10px 20px;\n}\n.customStartBtn:hover{\n    background-color: #5749f0;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
