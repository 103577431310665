const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  loading: false,
};

const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
});

export const { setIsLoading } = loaderSlice.actions;

export default loaderSlice.reducer;
