import { Modal, ModalText } from "views/Modals/Modal";
import { FormField } from "./FormField/FormField";
import deleteBin from "../../components/icons/delete.svg";
import close from "../../components/icons/close.svg";
import logo from "../../components/icons/logo.png";
import { FormHOC } from "./FormHOC";
import { useEffect, useState } from "react";
import { verifyToken } from "api/verifyToken";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteUserInfo } from "redux/userSlice";
function FormPage({
  openModal,
  type,
  handleSubmit,
  handleStateChange,
  handleDelete,
  smiles,
  form,
  setForm,
  countword,
  setOpenModal,
  setIsPaused,
  setSecondsRemaining
}) {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    async function validateToken() {
      const session = await verifyToken(token);
      if (!session) {
        dispatch(deleteUserInfo());
        navigate("/");
      }
    }
    validateToken();
  }, []);
  // console.log(setIsPaused, setSecondsRemaining, "setSecondsRemaining");
  const timeDuration = localStorage.getItem("timeDuration2");
  const [wordCount, setWordsCount] = useState(0);
  useEffect(() => {
    const savedWordCount = localStorage.getItem("wordCount");
    if (savedWordCount) {
      setWordsCount(savedWordCount);
    }
  }, []);
  function convertToHHMM(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    let formattedTime = "";
    if (hours > 0) {
      formattedTime += hours + " hour" + (hours > 1 ? "s" : "") + ", ";
    }
    if (minutes > 0) {
      formattedTime += minutes + " minute" + (minutes > 1 ? "s" : "") + ", ";
    }
    formattedTime +=
      remainingSeconds + " second" + (remainingSeconds > 1 ? "s" : "");
    return formattedTime;
  }
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const handleDeleteConfirmModel = () => {
    setOpenModalConfirm(true);
    setOpenModal(false);
  };
  const handleCloseModal = () => {
    localStorage.setItem('setOldValue', timeDuration);
    setOpenModalConfirm(false);
    setOpenModal(false);
  };
  const handleCancelDelete = () => {
    localStorage.setItem('setOldValue', timeDuration);
    setOpenModalConfirm(false);
    setOpenModal(false);
  };

  const handleSubmitNewSprint = (e) => {
    localStorage.removeItem("timeDuration");
    localStorage.removeItem("autosavedText");
    localStorage.removeItem("timeDuration2");
    localStorage.removeItem("wordCount");
    localStorage.removeItem("setOldValue");
    localStorage.removeItem("saveTimeMessage");
    window.location.reload();
  }

  return (
    <>
      <ModalText isOpen={openModal}>
        {/* <form className=""> */}
          <div className="w-inherit flex justify-start items-center flex-col">
            <h5 className="sprint_save">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <circle cx="11" cy="11" r="11" fill="#23D9A5" />
                <path
                  d="M5.87185 10.9689L9.40119 14.4501L15.6152 8.36725"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>{" "}
              &nbsp; Sprint Saved
            </h5>
            <h5 className="sprint_savewrote">
              You wrote {countword === 0 && wordCount}{" "}
              {countword !== 0 && countword} words in{" "}
              {convertToHHMM(timeDuration)}
            </h5>
            <div className="inline-block w-[90vw] sm:w-[50vw] lg:w-[35vw] rounded-2xl border-2 shadow-3xl border-[#5C2D8B] bg-white pl-5">
              <div className="w-full flex justify-around items-center">
                <div className="w-[80px]"></div>
                <h2 className="uppercase text-center text-2xl text-[#5C2D8B] my-5 add_sprint">
                   Add Sprint Details
                </h2>
                <div className="w-[80px] flex justify-end">
                  <button>
                    <img
                      src={close}
                      alt="close"
                      onClick={handleCloseModal}
                      style={{ width: "32px", height: "32px" }}
                    />
                  </button>
                </div>
              </div>
              <FormField
                title="Sprint Title"
                state={form.title}
                placeholder="Title"
                setState={(value) => handleStateChange("title", value)}
              />
              <FormField
                title="Notes"
                state={form.project}
                placeholder="Notes"
                setState={(value) => handleStateChange("project", value)}
              />
              <div className="w-[70vw] sm:w-[45vw] lg:w-[30vw]">
                <h4
                  className="mt-5 font-bold text-left"
                  style={{ textAlign: "left" }}
                >
                  How did it feel?
                </h4>
                <ul className="flex p-2 mb-4 gap-2">
                  {smiles.map((smile) => {
                    return (
                      <li key={smile.id}>
                        <button
                          className={
                            form.emotion === smile.img
                              ? "border-b border-black shadow w-8 h-8"
                              : "w-8 h-8"
                          }
                          aria-required
                          onClick={(e) => {
                            e.preventDefault();
                            return setForm((prevState) => {
                              return {
                                ...prevState,
                                emotion: smile.img,
                              };
                            });
                          }}
                        >
                          <img
                            src={smile.img}
                            className={
                              form.emotion === smile.img
                                ? "border-b border-black shadow w-8 h-8"
                                : "w-8 h-8"
                            }
                          />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="flex  w-11/12 px-4 justify-between">
                <button
                  className="mb-7 flex items-center text-[#5C2D8B]"
                  onClick={handleDeleteConfirmModel}
                >
                  <img
                    src={deleteBin}
                    alt="delete bin"
                    style={{ width: "32px", height: "32px" }}
                  />
                  Delete
                </button>
                <div className=" ">
                  <button
                    className="mb-[10px] px-6 rounded py-1 border-[#5C2D8B] border text-[#5C2D8B]"
                    type="submit"
                    onClick={handleSubmitNewSprint}
                  >
                    New Sprint
                  </button>
                  <button
                    className="mb-[10px] px-6 ml-6 text-white rounded py-1 bg-[#5C2D8B] "
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        {/* </form> */}
      </ModalText>
      <ModalText
        isOpen={openModalConfirm}
        onRequestClose={handleCancelDelete}
        contentLabel="Delete Confirmation"
      >
        <div>
          <div className="w-inherit flex justify-start items-center flex-col">
            <div className="inline-block w-[90vw] sm:w-[35vw] lg:w-[25vw] rounded-2xl border-2 shadow-3xl border-[#5C2D8B] bg-white pl-5">
              <div className="w-full flex justify-around items-center">
                <div className="w-[80px] new_logo">
                  <img src={logo}></img>
                </div>
                <div className="w-[100%] flex justify-end">
                  {/* <button>
                    <img
                      src={close}
                      alt="close"
                      style={{ width: "32px", height: "32px", marginTop:"10px" }}
                    />
                  </button> */}
                </div>
              </div>
              <div className="w-[80vw] sm:w-[30vw] lg:w-[23vw]">
                <h4
                  className="mt-5 font-bold text-center selectDuration "
                  style={{ textAlign: "center", marginTop: "20px", fontSize: "20px", cursor: "pointer" }}
                  onClick={handleCancelDelete}
                >
                  Delete
                </h4>
                <h5 className="mt-3 text-[#5C2D8B]">Are you sure you want to delete this sprint ?</h5>
              </div>
              <div className="d-flex mt-12 mb-7">
                <button onClick={handleCancelDelete} className="mb-[10px] px-6 rounded py-1 border-[#5C2D8B] border text-[#5C2D8B]">Cancel</button> &nbsp; &nbsp;
                <button onClick={handleDelete} className="mb-[10px] px-6 ml-6 text-white rounded py-1 bg-[#5C2D8B] ">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </ModalText>
    </>
  );
}
let WithFormPage = FormHOC(FormPage);
export default WithFormPage;