// import React from "react";
// import "../../css/modal.css";

// export const Modal = ({ isOpen, children }) => {
//   if (!isOpen) return null;

//   return <div className="modal-overlay">{children}</div>;
// };

// export const ModalText = ({ isOpen, children }) => {
//   if (!isOpen) return null;
//   return <div className="modal-overlay modal_overlay2">{children}</div>;
// };

import React, { useState, useEffect } from "react";
import "../../css/modal.css";
import Loader from "components/loader/loader";
export const Modal = ({ isOpen, children }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      {isLoading ? <Loader /> : children}
    </div>
  );
};
export const ModalText = ({ isOpen, children }) => {
  if (!isOpen) return null;
  if(!children){
    return <div className="modal-overlay"><Loader/></div>
  } else {
    return <div className="modal-overlay modal_overlay2">{children}</div>;
  }
};