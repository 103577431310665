import "../css/header.css";
import fullScreen from "../components/icons/expand.svg";
import { DisplaySVG } from "./DisplaySVG";
import { useEffect, useState } from "react";
import { Modal } from "./Modals/Modal";
import { Timer } from "./Timer";
import { AuthenticationScreen } from "./authentication_screen";
import { useSelector } from "react-redux";
import { QuestionListCalcSize } from "./QuestionList/QuestionListCalcSize";
import logo from "../components/icons/logo.png";
import Loader from "components/loader/loader";
import { useNavigate } from "react-router-dom";

export function Header({
  sprintId,
  timerTimes,
  value,
  setValue,
  countWords,
  textAreaDisable,
  setTextAreaDisable,
  setSprint,
  saveSprint,
}) {
  const { loading } = useSelector((state) => state.loader);
  const [isMobile, setIsMobile] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [serveyInfo, setServeyInfo] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const token = localStorage.getItem("token");

  const user = useSelector((state) => state.userInfo.user.id);
  const serveyOptions = useSelector(
    (state) => state.userInfo.user.options_servey
  );

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleFullscreen = () => {
    setIsFullscreen(true);
    const div = document.getElementById("header");
    if (div.requestFullscreen) {
      div.requestFullscreen();
    } else if (div.mozRequestFullScreen) {
      div.mozRequestFullScreen(); // Firefox
    } else if (div.webkitRequestFullscreen) {
      div.webkitRequestFullscreen(); // Safari
    } else if (div.msRequestFullscreen) {
      div.msRequestFullscreen(); // IE/Edge
    }
  };

  const handleExitFullscreen = () => {
    setIsFullscreen(false);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  };

  useEffect(() => {
    if (window.location.pathname === "/note-editor") {
      setModalOpen(true);
    }
    if (window.location.pathname === "/") {
      setModalOpen(true);
    }
    if (value !== 0) {
      return setModalOpen(false);
    }
    if (window.innerWidth <= 959) {
      setIsMobile(true);
    }
  }, [value]);

  const [timeDuration, setTimeDuration] = useState(1);
  // When the component mounts, retrieve the value of timeDuration from localStorage
  useEffect(() => {
    const storedTimeDuration = localStorage.getItem("timeDuration");
    const storedTimeDuration2 = localStorage.getItem("timeDuration2");
    const wordCount = localStorage.getItem("wordCount");
    const parsedTimeDuration =
      storedTimeDuration !== null ? parseFloat(storedTimeDuration) : 0;
    const parsedTimeDuration2 =
      storedTimeDuration2 !== null ? parseFloat(storedTimeDuration2) : 0;
    const newValue = parsedTimeDuration + parsedTimeDuration2;
    if (wordCount != 0) {
      setTimeDuration(newValue);
    } else {
      localStorage.removeItem("timeDuration2");
      setTimeDuration(0);
    }
  }, []);

  // When timeDuration changes, update timeDuration2 in localStorage
  useEffect(() => {
    localStorage.setItem("timeDuration2", timeDuration);
  }, [timeDuration]);

  return (
    <>
      <div className="w-full grid place-items-center">
        {!value && <img className="logo mt-[40px] " src={logo} alt="logo" />}
      </div>

      <div
        className={` ${
          isMobile ? "p-5" : "p-10"
        } w-full flex items-center justify-center relative  ${
          value === 0 && "blur-sm "
        }`}
      >
        <nav
          className={`w-full  text-center ${
            isMobile ? "" : "flex justify-between items-center"
          }`}
        >
          {isMobile || !user || value === 0 ? null : isFullscreen &&
            user &&
            value ? (
            <button className="fullScreen" onClick={handleExitFullscreen}>
              Exit Fullscreen
            </button>
          ) : (
            <button
              className="fullScreen text-[#5C2D8B]"
              onClick={handleFullscreen}
            >
              <DisplaySVG path={fullScreen} />
              Enter Fullscreen
            </button>
          )}

          {currentPath == "/note-editor" || currentPath == "/" ? (
            <div className="text-sm italic">
              {saveSprint === true ? (
                <div className="text-sm italic" style={{ display: "flex" }}>
                  {countWords}{" "}
                  {countWords === 0
                    ? "word"
                    : countWords === 1
                    ? "word"
                    : "words"}
                  , saved &nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <circle cx="9" cy="9" r="9" fill="#23D9A5" />
                    <path
                      d="M4.80426 8.97458L7.6919 11.8228L12.7761 6.84595"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              ) : (
                <div>
                  {countWords}{" "}
                  {countWords === 0
                    ? "word"
                    : countWords === 1
                    ? "word"
                    : "words"}{" "}
                  saving...
                </div>
              )}
            </div>
          ) : currentPath == "/note-editor-edit-page" ? (
            <div className="text-sm italic" style={{ display: "flex" }}>
              {countWords}{" "}
              {countWords === 0 ? "word" : countWords === 1 ? "word" : "words"},
              saved &nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <circle cx="9" cy="9" r="9" fill="#23D9A5" />
                <path
                  d="M4.80426 8.97458L7.6919 11.8228L12.7761 6.84595"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          ) : null}

          {value ? (
            <Timer
              isMobile={isMobile}
              value={value * 60}
              sprintId={sprintId}
              setTimeModal={modalOpen}
              countWords={countWords}
              secondsRemaining={secondsRemaining}
              setSecondsRemaining={setSecondsRemaining}
              textAreaDisable={textAreaDisable}
              setTextAreaDisable={setTextAreaDisable}
              setSprint={setSprint}
            />
          ) : (
            ""
          )}
        </nav>
      </div>
      <div>
        {(token && user && serveyInfo) || serveyOptions.length > 5 ? (
          <Modal isOpen={modalOpen} onClose={closeModal}>
            <div className="modal-content">
              <div className="setTimerContent">
                <h3 className="selectDuration">Select Duration</h3>
                <div className="btnContainer">
                  {timerTimes.map((value) => (
                    <button
                      key={value.id}
                      onClick={() => setValue(value.value)}
                      className="timerTimes"
                    >
                      {value.title}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            {(token && user && serveyInfo) ||
              (serveyOptions.length > 5 && (
                <div className="absolute flex justify-center w-full bottom-8 cursor-pointer ">
                  <button
                    onClick={() => navigate("/dashboard")}
                    className=" border border-[#5C2D8B] cursor-pointer bg-white text-[#5C2D8B] font-semibold rounded-xl px-6 py-2"
                  >{`Skip to Dashboard >`}</button>
                </div>
              ))}
          </Modal>
        ) : user ? (
          <QuestionListCalcSize setServeyInfo={setServeyInfo} />
        ) : (
          <Modal isOpen={modalOpen} onClose={closeModal}>
            <AuthenticationScreen />
          </Modal>
        )}
      </div>
    </>
  );
}
