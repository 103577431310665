
import React, { useState, useEffect, useRef } from "react";
import "../css/timer.css";
import { DisplaySVG } from "./DisplaySVG";
import pauseIcon from "../components/icons/pause.svg";
import stopIcon from "../components/icons/stop.svg";
import WithFormPage from "./FormPage/FormPage";
import WithFormPageSmall from "./FormPage/SmallFormPage";
import { verifyToken } from "api/verifyToken";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteUserInfo } from "redux/userSlice";
import startIcon from "../components/icons/start.svg";
import {
  inputSprintsTimes,
} from "redux/noteEditorSlice";

const STATUS = {
  STARTED: "Started",
  STOPPED: "Stopped",
};

export function Timer({
  value,
  isMobile,
  sprintId,
  secondsRemaining,
  setSecondsRemaining,
  textAreaDisable,
  setTextAreaDisable,
  setSprint,
}) {
  const INITIAL_COUNT = value;
  const [isStopped, setIsStopped] = useState(false);
  const [status, setStatus] = useState(STATUS.STOPPED);
  const [count, setCount] = useState(false);
  let secondsToDisplay = secondsRemaining / 60;
  const [isPaused, setIsPaused] = useState(false);
  const [pausedSeconds, setPausedSeconds] = useState(0);
  const [isCountingUp, setIsCountingUp] = useState(false);

  const [durationSeconds, setDurationSeconds] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [type, setType] = useState("");
  // console.log(durationSeconds, "durationSeconds");

  const [smallScreen, setSmallScreen] = useState(false);
  const [isGreenBg, setGreenBg] = useState(false);
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [seconds, setSeconds] = useState(1);  // Initial seconds (1 minute)
  const [isLocalTime, setLocalTime] = useState(false);

  const [isStoredTimeDuration, setStoredTimeDuration] = useState(0);


  useEffect(() => {
    setSprint((prevState) => {
      return { ...prevState, countTimes: durationSeconds };
    });
    dispatch(inputSprintsTimes(durationSeconds));
  }, [durationSeconds]);



  useEffect(() => {
    setInterval(() => {
      // Update the timer every second
      setSeconds(prevSeconds => prevSeconds + 1);
    }, 1000);
  }, []);
  

  console.log(durationSeconds, "durationSeconds");
  useEffect(() => {
    async function validateToken() {
      const session = await verifyToken(token);
      if (!session) {
        dispatch(deleteUserInfo());
        navigate("/");
      }
    }
    validateToken();
    if (window.innerWidth < 959) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, []);

  useEffect(() => {
    setStatus(STATUS.STARTED);
    setSecondsRemaining(INITIAL_COUNT);

    if(value === 600000){
      setCount(true)
    }
  }, [value]);

  useEffect(() => {
    if (status === "Started") {
      setGreenBg(true);
    } else {
      setGreenBg(false);
    }
  }, [status]);

  const handleStop = () => {
    if (status === STATUS.STARTED && !isPaused) {
      setIsPaused(true);
      setIsStopped(true);
      setStatus(STATUS.STOPPED);
      setTextAreaDisable(true);

      count === true ? setPausedSeconds(seconds) : setPausedSeconds(secondsRemaining / 60); // Save the remaining time when paused
      setSecondsRemaining(0);
    } else if (status === STATUS.STOPPED) {
      setIsPaused(false);
      setIsStopped(false);
      setStatus(STATUS.STARTED);
      setTextAreaDisable(false);

      count === true ? setSecondsRemaining(seconds) : setSecondsRemaining(pausedSeconds * 60); // Resume from where it was paused
    }
  };

  const handleReset = () => {

    if (status === STATUS.STARTED && !isPaused) {
      setIsPaused(true);
      setIsStopped(true);
      setStatus(STATUS.STOPPED);
      count === true ? setPausedSeconds(seconds) : setPausedSeconds(secondsRemaining / 60); // Save the remaining time when paused
      setSecondsRemaining(0);
    } 
    
    // else if (status === STATUS.STOPPED) {
    //   setIsPaused(false);
    //   setIsStopped(false);
    //   setStatus(STATUS.STARTED);
    //   count === true ? setSecondsRemaining(seconds) : setSecondsRemaining(pausedSeconds * 60); // Resume from where it was paused
    // }

    setStatus(STATUS.STOPPED);
    setSecondsRemaining(INITIAL_COUNT);
    setOpenModal(true);
    localStorage.getItem('wordCount');
    const storedTimeDuration = localStorage.getItem('timeDuration');
    setStoredTimeDuration(storedTimeDuration);
    if(isStoredTimeDuration != storedTimeDuration){
      const setOldValue = localStorage.getItem('setOldValue');
      const getmainvalue = storedTimeDuration - setOldValue
      const storedTimeDuration2 = localStorage.getItem('timeDuration2');
      const parsedTimeDuration = getmainvalue !== null ? parseFloat(getmainvalue) : 0;
      const parsedTimeDuration2 = storedTimeDuration2 !== null ? parseFloat(storedTimeDuration2) : 0;
      const newValue = parsedTimeDuration + parsedTimeDuration2;
      localStorage.setItem('timeDuration2', newValue);
    }
    setTextAreaDisable(true);
    setType("Save");
  };

  localStorage.setItem('timeDuration', durationSeconds);

  // useInterval(
  //   () => {
  //     if (isCountingUp) {
  //       // Counting up from 1 to infinity
  //       if(count){
  //         setSecondsRemaining(formatTime(seconds));
  //       } else {
  //         setSecondsRemaining((seconds) => seconds + 1);
  //       }
  //     } else if (secondsRemaining > 0) {
  //       // Counting down from the initial value to 0
  //       setSecondsRemaining((seconds) => seconds - 1);
  //     } else {
  //       // Switch to counting up when seconds reach 0
  //       setIsCountingUp(true);
  //     }

  //     const newDurationSeconds = value - secondsRemaining;
  //     setDurationSeconds(newDurationSeconds);
  //   },
  //   status === STATUS.STARTED ? 1000 : null
  // );

  useInterval(
    () => {
      if (isCountingUp) {
        if (secondsRemaining === 1) {
          // Add 15 minutes (900 seconds) and start counting from 16 minutes (960 seconds)
          setSecondsRemaining(INITIAL_COUNT);
        } else {
          // Counting up from 1 to infinity
          if (count) {
            setSecondsRemaining(formatTime(seconds));
          } else {
            setSecondsRemaining((seconds) => seconds + 1);
          }
        console.log(secondsRemaining);
        }
      } else if (secondsRemaining > 0) {
          // Check if the timer duration exists in local storage
       const timeDuration = localStorage.getItem('timeDuration2');
        if(timeDuration !== null || timeDuration !== 0){
          if(isLocalTime == false) {
            setSecondsRemaining((seconds) => seconds - 1 - timeDuration );
          }
        }
        setLocalTime(true)
        setSecondsRemaining((seconds) => seconds - 1 );
      } else {
        // Switch to counting up when seconds reach 0
        setIsCountingUp(true);
      }
      let newDurationSeconds;
      if(value < secondsRemaining){
         newDurationSeconds =  secondsRemaining;
      }else{
         newDurationSeconds = value - secondsRemaining;
      }
      setDurationSeconds(newDurationSeconds);
    },
    status === STATUS.STARTED ? 1000 : null
  );
  

  function convertToHHMM(decimalHours) {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);

    // Ensure the hours and minutes are displayed with leading zeros if needed
    const formattedTime =
      (hours < 10 ? "0" : "") +
      hours +
      ":" +
      (minutes < 10 ? "0" : "") +
      minutes;
    return formattedTime;
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div
      className={`flex gap-5 ${isMobile ? "justify-between" : "justify-end"}`}
    >
      {smallScreen ? (
        <WithFormPageSmall
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          sprintId={sprintId}
          setIsStopped={setIsStopped}
          setStatus={setStatus}
          STATUS={STATUS}
          type={type}
          durationSeconds={durationSeconds}
        />
      ) : (
        <WithFormPage
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          sprintId={sprintId}
          setIsStopped={setIsStopped}
          setStatus={setStatus}
          STATUS={STATUS}
          type={type}
          durationSeconds={durationSeconds}
          isPaused={isPaused}
          setIsPaused={setIsPaused}
          count= {count}
          setPausedSeconds={setPausedSeconds}
          seconds={seconds}
          secondsRemaining={secondsRemaining}
          setSecondsRemaining={setSecondsRemaining}
          pausedSeconds={pausedSeconds}
          setOpenModal={setOpenModal}
        />
      )}
      <div
        className={`w-full flex gap-5 ${
          isMobile ? "flex-row-reverse" : ""
        } justify-between`}
      >
        <div className="flex gap-5">
          <button
          className={`navButton purpleFrame text-[#5C2D8B] ${isStopped ? 'startButton' : ''}`}
          onClick={handleStop}
          >
            {isStopped ? (
             <>
             <DisplaySVG
                path={startIcon}
                style={{
                  width: "34px",
                  height: "34px",
                  paddingRight: "12px",
                  paddingLeft: "12px",
                  paddingTop: "9px",
                  paddingBottom: "9px",
                  fill: "#FFFFFF",
                }}
                />
                Start
             </>
            ) : (
              <>
                <DisplaySVG
                  path={pauseIcon}
                  style={{
                    width: "32px",
                    height: "32px",
                    paddingRight: "12px",
                    paddingLeft: "12px",
                    paddingTop: "9px",
                    paddingBottom: "9px",
                  }}
                />
                {isMobile ? "" : "Pause"}
              </>
            )}
          </button>
          <button className="navButton redFrame" onClick={handleReset}>
            <DisplaySVG path={stopIcon} />
            {isMobile ? "" : "Stop"}
          </button>
        </div>
        <div className={isGreenBg ? "greenTimer" : "purpleTimer"}>
          <span className={isGreenBg ? "greenCircle" : "purpleCircle"}></span>
          {isStopped
            ? `${count === true ? formatTime(pausedSeconds) : convertToHHMM(pausedSeconds)}`
            : `${count === true ? formatTime(seconds) : convertToHHMM(secondsToDisplay)}`}
        </div>
      </div>
    </div>
  );
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
