// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL(".././../public/images/Union.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollbar-hide::-webkit-scrollbar {
    display: none;
}
.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.new_logout{
    position: absolute;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    padding: 21px 48px;
    background-position: -4px -23px;
    top: 74px;
    right: 5px;
    /* margin-left: 24px !important; */
    background-size: 167px 98px;
    border-radius: 7px;
    /* border: 4px solid #5C2D8B; */
    /* clip-path: polygon(25% 25%, 54.75% 25%, 60.87% 14.41%, 66.98% 25%, 75% 25%, 75% 75%, 25% 75%); */
    color: #1A062E;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 67px; */
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/css/dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,wBAAwB;IACxB,qBAAqB;AACzB;AACA;IACI,kBAAkB;IAClB,yDAAsD;IACtD,kBAAkB;IAClB,+BAA+B;IAC/B,SAAS;IACT,UAAU;IACV,kCAAkC;IAClC,2BAA2B;IAC3B,kBAAkB;IAClB,+BAA+B;IAC/B,mGAAmG;IACnG,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,uBAAuB;IACvB,eAAe;AACnB","sourcesContent":[".scrollbar-hide::-webkit-scrollbar {\n    display: none;\n}\n.scrollbar-hide {\n    -ms-overflow-style: none;\n    scrollbar-width: none;\n}\n.new_logout{\n    position: absolute;\n    background-image: url(.././../public/images/Union.svg);\n    padding: 21px 48px;\n    background-position: -4px -23px;\n    top: 74px;\n    right: 5px;\n    /* margin-left: 24px !important; */\n    background-size: 167px 98px;\n    border-radius: 7px;\n    /* border: 4px solid #5C2D8B; */\n    /* clip-path: polygon(25% 25%, 54.75% 25%, 60.87% 14.41%, 66.98% 25%, 75% 25%, 75% 75%, 25% 75%); */\n    color: #1A062E;\n    font-family: Mulish;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 700;\n    /* line-height: 67px; */\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
