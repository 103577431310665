import { StartPage } from "views/welcome";
import { AuthenticationScreen } from "views/authentication_screen";
import { QuestionList } from "views/question_list";
import { NoteEditor } from "views/NoteEditor/NoteEditor";
import { Dashboard } from "views/Dashboard/Dashboard";
import { NanoTracker } from "views/NanoTracker/NanoTracker";



const Routes = [
  {
    path: "/",
    view: NoteEditor,
    title: "Start Writting",
  },
  // {
  //   path: "/",
  //   view: AuthenticationScreen,
  //   title: "Start Writting",
  // },
  {
    path: "questionList",
    view: QuestionList,
    title: "Question List",
  },
  {
    path: "/note-editor",
    view: NoteEditor,
    title: "Note Editor",
  },
  // {
  //   path: "/note-editor/authentication",
  //   view: AuthenticationScreen,
  // },
  {
    path: "/dashboard",
    view: Dashboard,
    title: "Dashboard",
  },
  {
    path: "/nano-tracker",
    view: NanoTracker,
    title: "Nano Tracker",
  },
];

export default Routes;
