import { useAutosave } from "hooks/useAutoSave";
import React, { useState, useEffect, Fragment } from "react";
import { Header } from "views/Header";
import { useAmountWords } from "hooks/useAmountWords";
import { nanoid } from "nanoid";
import { useDispatch } from "react-redux";
import {
  updateCountWords,
  setSprintId,
  inputSprintsText,
} from "redux/noteEditorSlice";
import { useNavigate } from "react-router-dom";

const timerTimes = [
  { id: 1, title: "15 Minutes", value: 15 },
  { id: 2, title: "30 Minutes", value: 30 },
  { id: 3, title: "45 Minutes", value: 45 },
  { id: 4, title: "Timed Sprint", value: 10000 },
];

export function NoteEditor() {
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [textAreaDisable, setTextAreaDisable] = useState(false);

  const id = nanoid(5);
  const [text, setText] = useState("");
  const [sprint, setSprint] = useState({
    content: "",
    id: id,
    duration: 0,
    countWords: 0,
    createdAt: "",
    metaData: {},
    countTimes: 0,
  });

  const token = localStorage.getItem("token");
  const [wordCount, setWordsCount] = useState(0);
  const [saveSprint, setSaveSprint] = useState(false);

  useEffect(() => {
    const savedWordCount = localStorage.getItem("wordCount");
    if (savedWordCount) {
      setWordsCount(savedWordCount);
    }
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let timer = setTimeout(() => {
      setSaveSprint(true);
    }, 3000);
    return () => clearTimeout(timer); // Clear the timer on unmount
  }, [sprint.countWords]); // Add sprint.countWords as a dependency

  useEffect(() => {
    setSaveSprint(false);
  }, [sprint.countWords]);

  //setSprint countWords
  let integer = useAmountWords(text);
  useEffect(() => {
    setSprint((prevState) => {
      return { ...prevState, countWords: integer };
    });
    if (sprint.countWords == 0) {
      sprint.countWords = wordCount;
    }
    // setup to state countWords
    dispatch(updateCountWords(sprint.countWords));
  }, [text, integer, wordCount, dispatch]);

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();
    const currentHours = currentDate.getHours();
    const currentMinutes = currentDate.getMinutes();
    const currentSeconds = currentDate.getSeconds();

    const date = `${currentYear}-${currentMonth}-${currentDay} ${currentHours}:${currentMinutes}:${currentSeconds}`;

    // setSprint created at
    setSprint((prevState) => {
      return { ...prevState, createdAt: date };
    });
    // dispatch(addSprint(sprint));
    dispatch(setSprintId(sprint.id));

    const textarea = document.getElementById("customTextarea");

    value !== 0 &&
      textarea.addEventListener("paste", (event) => {
        event.preventDefault();
      });
  }, []);

  useEffect(() => {
    setSprint((prevState) => {
      return { ...prevState, content: text };
    });
    dispatch(inputSprintsText(text));
  }, [text]);

  useAutosave(sprint);
  useEffect(() => {
    const savedText = localStorage.getItem("autosavedText");
    if (savedText) {
      setText(savedText);
    }
  }, []);
  // Autosave the text to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("autosavedText", text);
    const words = text.split(/\s+/);
    const filteredWords = words.filter((word) => word.trim() !== "");
    const wordCount = filteredWords.length;
    localStorage.setItem("wordCount", wordCount.toString());
  }, [text]);
  return (
    <>
      <div id="header" className="bg-[#F4F4F4] h-screen relative">
        <Header
          sprintId={sprint.id}
          timerTimes={timerTimes}
          value={value}
          setValue={setValue}
          countWords={sprint.countWords}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          textAreaDisable={textAreaDisable}
          setTextAreaDisable={setTextAreaDisable}
          setSprint={setSprint}
          saveSprint = {saveSprint}
        />

        <div className=" bg-[#F4F4F4] relative">
          {value !== 0 && (
            <section className="flex justify-center items-center ">
              <div className="w-full flex justify-center items-center">
                <textarea
                  id="customTextarea"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  className="w-full sm:w-[880px]  lg:w-10/12 lg:rounded-[20px] min-h-screen overflow-scroll scrollbar-hide focus:outline-none p-10 rounded-[15px] font-courier"
                  style={{ whiteSpace: "pre-wrap" }}
                  placeholder={
                    value ? "Let the words flow through your fingers..." : " "
                  }
                  disabled={textAreaDisable}
                />
              </div>
            </section>
          )}
        </div>
      </div>
    </>
  );
}
