import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import useAPI from "api/serviceApi";

export const postSprint = createAsyncThunk(
  "note-editor/postSprint",
  async ({ sprint, userId, token }, thunkAPI) => {
    try {
      await useAPI.uploadSprint(sprint, userId, token);
      return sprint;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getSprints = createAsyncThunk(
  "note-editor/getSprints",
  async ({ userId, token }, thunkAPI) => {
    try {
      const response = await useAPI.fetchSprints(userId, token);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateSprint = createAsyncThunk(
  "note-editor/updateSprint",
  async ({ updatedSprint, token }, thunkAPI) => {
    try {
      const response = await useAPI.sendUpdatedSprint(updatedSprint, token);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const noteEditorSlice = createSlice({
  name: "note-editor",
  initialState: {
    sprints: [],
    sprint: {
      content: "",
      id: "",
      duration: 0,
      speed: 0,
      countWords: 0,
      title: "",
      project: "",
      emotion: "",
    },
  },
  reducers: {
    updateCountWords: (state, action) => {
      state.sprint.countWords = action.payload;
    },
    setSprintId: (state, action) => {
      state.sprint.id = action.payload;
    },
    updateLocalSprint: (state, action) => {
      const existingIndex = state.sprints.findIndex(
        (i) => i.id === action.payload.id
      );
      state.sprints.splice(existingIndex, 1, {
        ...state.sprints[existingIndex],
        title: action.payload.title,
        emotion: action.payload.emotion,
      });
    },
    autoUpdateSprint: (state, action) => {
      state.sprint.content = action.payload.content;
    },
    inputSprintsText: (state, action) => {
      state.sprint.content = action.payload;
    },
    inputSprintsTimes: (state, action) => {
      state.sprint.countTimes = action.payload;
    },
    addMetaData: (state, action) => {
      const index = state.sprints.findIndex(
        (i) => i.id === action.payload.sprintId
      );
      const { title, project, emotion } = action.payload.form;
      state.sprint.title = title;
      state.sprint.project = project;
      state.sprint.emotion = emotion;
    },
    clearSprintData: (state, action) => {
      state.sprint = {
        content: "",
        id: "",
        countWords: 0,
        metaData: {},
      };
    },
    calcDurationAndSpeed: (state, action) => {
      const seconds = action.payload.duration;

      const calcSpeed = (state.sprint.countWords / seconds) * 60;
      const roundedSpeed = Math.round(calcSpeed * 100) / 100;
      const cutdownSpeed = roundedSpeed.toFixed(2);
      state.sprint.speed = cutdownSpeed;
      if (seconds <= 60) {
        state.sprint.duration = `${seconds} sec`;
      } else {
        // const calcDuration = seconds / 100;
        // const cutdownDuration = calcDuration.toFixed(2);
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        state.sprint.duration = `${
          minutes < 2 ? `${minutes} min` : `${minutes} mins`
        } ${remainingSeconds} sec`;
      }
    },
    deleteSprintInfo: (state, action) => {
      state.sprint = {
        content: "",
        id: "",
        duration: 0,
        speed: 0,
        countWords: 0,
        title: "",
        project: "",
        emotion: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postSprint.fulfilled, (state, action) => {
      state.sprints.push(action.payload);
    });
    builder.addCase(postSprint.rejected, (state, action) => {
      console.log(action.payload);
    });
    builder.addCase(getSprints.fulfilled, (state, action) => {
      state.sprints = action.payload;
    });
    builder.addCase(getSprints.rejected, (state, action) => {
      console.log(action.payload);
    });
    builder.addCase(updateSprint.rejected, (state, action) => {
      console.log(action.payload);
    });
  },
});

export const deleteSprint = createAsyncThunk(
  "sprint/deleteSprintj",
  async ({ sprintId, token }, thunkAPI) => {
    try {
      // Call your delete API function (e.g., useAPI.deleteSprint) here
      const response = await useAPI.sendDeletedSprint(sprintId, token);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const {
  autoUpdateSprint,
  inputSprintsText,
  inputSprintsTimes,
  updateCountWords,
  updateLocalSprint,
  setSprintId,
  addMetaData,
  clearSprintData,
  calcDurationAndSpeed,
  deleteSprintInfo,
  sendDeletedSprint,
} = noteEditorSlice.actions;

export default noteEditorSlice.reducer;
