import { useLocalTime } from "hooks/useLocalTime";
import pencil from "../../../components/icons/pencil.svg";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { updateSprint, updateLocalSprint } from "redux/noteEditorSlice";
import extremelySadIcon from "../../../components/icons/animation_too_sad.gif";
import neutralIcon from "../../../components/icons/animation_not_good.gif";
import sadIcon from "../../../components/icons/animation_normal.gif";
import happyIcon from "../../../components/icons/animation_happy.gif";
import extremelyHappyIcon from "../../../components/icons/animation_loved.gif";
import { Navigate } from "react-router-dom";
import WithEditFormPage from "views/FormPage/EditFormPage";
const smiles = [
  { id: 0, title: "excited", img: extremelyHappyIcon },
  { id: 1, title: "good", img: happyIcon },
  { id: 2, title: "normal", img: sadIcon },
  { id: 3, title: "sad", img: neutralIcon },
  { id: 4, title: "extremely sad", img: extremelySadIcon },
];
export function SprintInfo({ sprint, isActive, type, setType, index }) {
  const convertedTime = useLocalTime(sprint.created_at);
  const [datePart, timePart] = convertedTime.split(" | ");
  const [month, day, year] = datePart.split(" ");
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const formattedDate = `${monthNames[parseInt(month) - 1]} ${day} ${year}`;
  // Splitting the timePart into hours and minutes and formatting it as HH:mm
  const [hours, minutes] = timePart.split(":");
  const formattedTime = `${hours}:${minutes.padStart(2, '0')}`;
  const formattedDateTime = `${formattedDate} | ${formattedTime}`;
  const [value, setValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [sprintData, setsprintData] = useState();
  const [editedSprint, setSprint] = useState({
    title: "",
    emotion: "",
    id: "",
    user_id: "",
  });
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  useEffect(() => {
    setSprint((prevState) => {
      return {
        ...prevState,
        title: sprint.title,
        emotion: sprint.emotion,
        id: sprint.id,
        user_id: sprint.user_id,
      };
    });
  }, []);
  const handleChange = (fieldName, e) => {
    setSprint((prevState) => {
      return { ...prevState, [fieldName]: e.target.value };
    });
  };
  const handleSubmit = () => {
    setType("view");
    dispatch(updateLocalSprint(editedSprint));
    dispatch(updateSprint({ updatedSprint: editedSprint, token }));
  };
  const handleClick = () => {
    // Set the state value
    setValue(10000);
  };
  const handleModelUpdate = (sprint) => {
    // Set the state value
    setOpenModal(true)
    setsprintData(sprint);
  };
  return (
    <>
      {isActive === sprint.id && (
        <div className="w-full ">
          <div className="flex justify-between  mb-[20px]">
            <div>
              <p className="font-mulish text-[#5C2D8B] text-[18px] font-[700]">
                {type === "edit" ? (
                  <input
                    type="text"
                    value={editedSprint.title}
                    onChange={(e) => handleChange("title", e)}
                  />
                ) : (
                  sprint.title || "Sprint " + index
                  )}
              </p>
              <p className="text-[#A6A3A9] text-[14px] font-[400] font-mulish">
                {formattedDateTime}
              </p>
            </div>
            {type === "edit" ? (
              <button onClick={() => handleSubmit()}>Submit</button>
            ) : (
              <div className="btnContainer">
                <div
                  style={{ cursor: 'pointer', display: 'inline-block' }}
                  onClick={handleClick}
                >
                  <img
                    src={pencil}
                    alt="edit"
                    style={{ width: '24px', height: '100%' }}
                    onClick={() => handleModelUpdate(sprint)}
                  />
                </div>
                {/* {value === 10000 &&
                <Navigate to="/note-editor-edit-page" state={{ value: 10000, sprintData: sprint }} />
                } */}
                  <WithEditFormPage
                    setOpenModal={setOpenModal}
                    openModal={openModal}
                    sprintData={!sprintData ? sprint : sprintData}
                    setType={setType}
                    setsprintData={setsprintData}
                  />
              </div>
            )}
          </div>
          <div className=" ">
            <div className="flex justify-around bg-[#F4F1F9] px-[28px] py-[16px] rounded-[10px] mb-[40px]">
              <div className="flex justify-center items-center gap-3">
                <p className="text-[#A6A3A9] text-[14px] font-[500] font-mulish">
                  Words
                </p>
                <span className="text-[20px] font-[700] font-mulish">
                  {sprint.count_words}
                </span>
              </div>
              <div className="flex justify-center items-center gap-3">
                <p className="text-[#A6A3A9] text-[14px] font-[500] font-mulish">
                  Duration
                </p>
                <span className="flex justify-end text-[20px] font-[700] font-mulish">
                  {sprint.duration}
                </span>
              </div>
              <div className="flex justify-center items-center gap-3">
                <p className="text-[#A6A3A9] text-[14px] font-[500] font-mulish">
                  Speed
                </p>
                <span className="flex justify-end text-[20px] font-[700] font-mulish">
                  {sprint.speed} wpm
                </span>
              </div>
            </div>
            <div className="pb-10 border-b-2">
              <p
              className="text-[#1A062E] text-[18px] loading-[24px] font-[400] font-courier"
              style={{ whiteSpace:"pre-wrap" }}
              >
                {sprint.content}
              </p>
            </div>
            <div className="border-b-2 py-10">
              <p className="text-[16px] font-[400] font-mulish">
                How did you feel?
              </p>
              {type === "edit" ? (
                <ul className="flex markers:none">
                  {smiles.map((smile) => {
                    return (
                      <li key={smile.id} className="mr-3">
                        <button
                          aria-required
                          onClick={(e) => {
                            e.preventDefault();
                            return setSprint((prevState) => {
                              return {
                                ...prevState,
                                emotion: smile.img,
                              };
                            });
                          }}
                        >
                          <img src={smile.img} className="w-8 h-8" />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                sprint.emotion ? (
                  <img src={sprint.emotion} alt="emotion" className="w-10" />
                ) : (
                  <span><b>-_-</b></span>
                )              
              )}
            </div>
            <div className="py-10">
              <p className="text-[16px] font-[400] font-mulish">Notes</p>
              <p className="text-[#A6A3A9] text-[15px] font-[400] font-mulish">
              {!sprint.project ? 'No notes for this activity' : sprint.project}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

